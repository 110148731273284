$darkBlue: #0a2839;
$sterlingBlue: #328ef7;
$aqua: #71cdda;
$grey: rgb(239, 239, 239);

@import 'bootstrap.min.scss';

* {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
}

body {
  background-color: $grey;
}

h1, h2, h3, h4, h5, h6 {
  color: $sterlingBlue;
  font-weight: 700;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background: $darkBlue;
  
  @media(min-width: 960px) {
    padding: 0 100px;
  }

  .navbar-brand {
    padding: 0;

    img {
      width: 200px;
      height: auto;
    }
  }

  .navbar-collapse {
    flex-grow: 0;

    .navbar-nav .nav-item .nav-link {
      letter-spacing: .5px;
      color: $grey;
      margin-right: 16px;
    }
  }
}

.hero {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 500px;
  background: url('../img/hero.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .hero-image {
    width: 400px;
    height: auto;
    z-index: 2;
  }
  h2 {
    color: white;
    z-index: 2;
    margin-top: -20px;
    letter-spacing: 2px;
  }
}
.hero:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($aqua, 0.7);
}

.info,
.contact {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 300px;
  
  .info-wrapper,
  .contact-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    text-align: center;

    @media(min-width: 900px) {
      width: 60%;
    }

    @media(min-width: 1260px) {
      width: 40%;
    }

    p {
      font-size: 16px;
    }
  }
}

.contact {
  background: $aqua;

  .contact-wrapper {
    @media(min-width: 900px) {
      width: 50%;
    }
    @media(min-width: 1300px) {
      width: 35%;
    }

    span {
      font-size: 24px;
      font-weight: 400;
      color: white;
    }
    p {
      font-weight: 700;
      margin-top: 8px;
    }

    .contact-us-btn {
      color: black;
      background-color: white;
      border-radius: 25px;
      font-size: 1rem;
      font-weight: 700;
      padding: .75rem 1.5rem;
    }
  }
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 50px 0;
}

.card-row {
  width: 90%;
}

.service-header {
  font-size: 15px;
  font-weight: 700;
  color: black;
  margin-bottom: 16px;
}

.service-card,
.client-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 300px;
  margin: 4px 4px;
  padding: 0 16px;

  .card-body img {
    width: 100px;
    height: auto;
    min-height: 100px;
    margin-bottom: 16px;
  }
  .card-body img {
    stroke: black;
  }
}

.client-card .card-body img {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.language-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 300px;
  height: 180px;
  margin: 4px 4px;
  padding: 16px;

  .card-body img {
    width: 100px;
    height: auto;
    min-height: 100px;
    margin-bottom: 16px;
  }
  .card-body img {
    stroke: black;
  }
  .card-title {
    margin-top: 8px;
  }
}

footer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $darkBlue;
  padding: 40px 0 20px 0;


  .footer-image {
    width: 250px;
    height: auto;
  }

  .nav .nav-item .nav-link {
    color: $grey;
    font-size: 18px;
  }

  .copy {
    font-size: 12px;
    margin-top: 50px;
    color: $grey;
  }
}